export const BD_DATA_KEYS = {
  VC: 'vehicleCount',
};

export const BD_DATA_TITLES = {
  [BD_DATA_KEYS.VC]: 'Автомобилей',
};

export const mapBatteryDataCharts = (report = {}) => {
  const { items = [] } = report;
  if (!items || items.length === 0) {
    return [];
  }
  const data =
    items.map(({ odometerEnd, batteryVoltageIdlingAvg, batteryVoltageMovingAvg }) => {
      return {
        name: odometerEnd,
        batteryVoltageIdlingAvg: batteryVoltageIdlingAvg,
        batteryVoltageMovingAvg: batteryVoltageMovingAvg,
      };
    }) || [];
  const { odometerStart, batteryVoltageIdlingAvg, batteryVoltageMovingAvg } = items[0];
  // задаем начало первых данных из пакета
  data.unshift({
    name: odometerStart,
    batteryVoltageIdlingAvg,
    batteryVoltageMovingAvg,
  });
  return data;
};
