export const ROLES = {
  admins: 'admins',
  oem: 'oem',
  dealer: 'dealer',
  marketing: 'marketing',
};

export const NAV_MENU = [
  { key: '/vehicleCreate', title: 'Создание ТС', ability: [ROLES.admins, ROLES.dealer] },
  { key: '/vehicles', title: 'Транспортные средства', ability: [ROLES.admins, ROLES.dealer, ROLES.oem] },
  { key: '/notifications', title: 'Уведомления', ability: [ROLES.admins, ROLES.dealer, ROLES.oem] },
  // { key: '/news', title: 'Новости', ability: [ROLES.admins, ROLES.marketing] },
  { key: '/dealers', title: 'Дилерские центры', ability: [ROLES.admins] },
  { key: '/reports', title: 'Отчеты', ability: [ROLES.admins, ROLES.oem] },
  { key: '/maintenance', title: 'Регламенты ТО', ability: [ROLES.admins] },
];

export const oidcConfig = {
  authority: process.env.REACT_APP_AUTH_URL,
  client_id: process.env.REACT_APP_IDENTITY_CLIENT_ID,
  redirect_uri: process.env.REACT_APP_REDIRECT_URL,
  silent_redirect_uri: process.env.REACT_APP_SILENT_REDIRECT_URL,
  response_type: 'code',
  scope: process.env.REACT_APP_AUTH_SCOPE,
  automaticSilentRenew: true,
  loadUserInfo: true,
  monitorSession: false,
  post_logout_redirect_uri: process.env.REACT_APP_SIGNOUT_REDIRECT_URL,
};
