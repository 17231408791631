import { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { newsService } from 'services';
import { COLUMNS } from './helpers';

import { NewsCreate } from './NewsCreate';
import { Table, Paper, ButtonTypes, Button, pageSize } from '@bs/techconnect-ui';
import { ConfirmModal } from 'components/ConfirmModal/ConfirmModal';
import { ReactComponent as Edit } from 'icons/edit.svg';
import { ReactComponent as Delete } from 'icons/delete.svg';
import { ReactComponent as Add } from 'icons/plus.svg';

import styles from './News.module.css';

const News = () => {
  const [searchParams] = useSearchParams();
  const page = searchParams.get('page') || 1;

  const [news, setNews] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [editModal, setEditModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [deleteModal, setDeleteModal] = useState(false);

  const fetchNews = useCallback(() => {
    setLoading(true);
    newsService
      .getNews({ page, pageSize })
      .then(({ status, data }) => {
        if (status === 200 && Array.isArray(data?.items)) {
          setNews(
            data.items.map((news = {}) => {
              const openedCount = news.stats?.openedCount;
              return { ...news, openedCount };
            }),
          );
        }
        if (!isNaN(data.totalPages)) setTotalPages(data.totalPages);
      })
      .finally(() => setLoading(false));
  }, [page]);

  useEffect(() => fetchNews(), [page, fetchNews]);

  const handler = row => ({
    ...row,
    edit: (
      <Button
        variant={ButtonTypes.TL}
        onClick={() => {
          setEditModal(row);
        }}
      >
        <Edit />
      </Button>
    ),
    delete: (
      <Button variant={ButtonTypes.TL} onClick={() => setDeleteModal(row.id)}>
        <Delete />
      </Button>
    ),
  });

  return (
    <>
      <Paper className={styles['filters-wrapper']}>
        <header>
          <span>Новости</span>
          <Button variant={ButtonTypes.S} onClick={() => setEditModal(!editModal)}>
            <Add />
          </Button>
        </header>
      </Paper>
      <Table
        className={styles['table']}
        columns={COLUMNS}
        rows={news}
        totalPages={totalPages}
        idKey="id"
        handler={handler}
        loading={loading}
      />
      <NewsCreate news={editModal} open={!!editModal} setOpen={() => setEditModal(!editModal)} updateList={fetchNews} />
      <ConfirmModal
        id={deleteModal}
        updateList={fetchNews}
        setOpen={() => setDeleteModal(!deleteModal)}
        method={newsService.deleteNews}
        header="Удалить новость?"
        description="Я понимаю, что восстановить новость будет невозможно"
      />
    </>
  );
};

export default News;
