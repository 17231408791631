import { ReactComponent as Route } from 'icons/route.svg';
import { VEHICLE_STATUS } from 'components/Vehicles/helpers';
import { EMPTY, parseDate, UNITS } from '@bs/techconnect-ui';
import { differenceInDays, differenceInHours, differenceInMinutes, isValid } from 'date-fns';

export const isOptionEnable = (optionValues, key) =>
  optionValues.some(o => o?.option?.key === key && o?.value === 'true');
const isHasOption = (optionValues, key) => optionValues.find(o => o?.option?.key === key);

export const getFuelLevelPercent = (value, vehicle) => {
  const { optionValues = [] } = vehicle || {};
  const { value: max } = isHasOption(optionValues, 'tankVolume') || {};
  const { value: source } = isHasOption(optionValues, 'fuelDataSource') || {};
  if (isNaN(max) || isNaN(value) || !source) return [0, 0];
  const result = source === FUEL_SOURCE_ENUM.l ? (value * 100) / max : value;
  return [result, result];
};
export const getEngineSpeedPercent = (value, vehicle) => {
  const { optionValues = [] } = vehicle || {};
  const { value: max } = isHasOption(optionValues, 'maxEngineSpeed') || {};

  if (isNaN(value) || isNaN(max)) return [0, 0];
  const result = parseInt((value * 100) / max);
  return [result, value];
};
const addUTCmark = date => {
  if (!date) return '';
  return !date?.endsWith('Z') ? date + 'Z' : date;
};

export const isActualFullData = state => {
  const { createdAt, powerPackStatus, lastActivity } = state;
  let dateFromState = createdAt || lastActivity;
  if (powerPackStatus === 0) return false;
  if (dateFromState && !dateFromState.endsWith('Z')) dateFromState += 'Z';
  const dateNow = new Date();
  const dateFromDevice = new Date(dateFromState || lastActivity);
  const dateNowPerMls = dateNow.getTime();
  const dateFromDevicePerMls = dateFromDevice.getTime();
  if (dateFromState) {
    const difference = dateNowPerMls - dateFromDevicePerMls;
    return difference < 300000;
  } else return false;
};

export const STATE = {
  ST: 'status',
  LA: 'lastActivity',
  IG: 'ignition',
  SP: 'speed',
  OD: 'odometer',
  ECT: 'engineCoolantTemperature',
  BV: 'batteryVoltage',
  ES: 'engineSpeed',
  EL: 'engineLoad',
  MIL: 'indicatorMIL',
  APP: 'accelPedalPosition',
  BPP: 'brakePedalPosition',
  СPP: 'clutchPedalPosition',
  MBS: 'mainBeamStatus',
  PLS: 'positionLightsStatus',
  HBS: 'highBeamStatus',
  DD: 'driverDoor',
  PD: 'passDoor',
  FL: 'fuelLevel',
  FDD: 'fuelDTE',
  FI: 'fuelIfe',
  TFU: 'totalFuelUsed',
  FA: 'fuelAfe',
  K: 'key',
  FR: 'fuelRate',
  AAT: 'ambientAirTemperature',
  DCL: 'dpfCommandLamp',
  DTL: 'dpfTemperatureLamp',
  DRIS: 'dpfRegenInhibitStatus',
  DP: 'dpfPressure',
  SL: 'sootLoad',
  SRL: 'scrReagentLevel',
  SRT: 'scrReagentTemperature',
};

export const FIELD_TYPE = { d: 'double', s: 'single', e: 'empty' };

export const MAIN_FIELDS = [
  {
    key: STATE.OD,
    title: 'Пробег',
    unit: UNITS.mileage,
    type: FIELD_TYPE.d,
    icon: <Route />,
    dependsOnTime: false,
  },
  {
    key: STATE.ES,
    title: 'Обороты двигателя',
    unit: UNITS.engineSpeed,
    type: FIELD_TYPE.d,
    progress: getEngineSpeedPercent,
    withoutBar: true,
    dependsOnTime: true,
    visible: oV => isOptionEnable(oV, 'hasEngineSpeedData'),
  },
  {
    key: STATE.SP,
    title: 'Скорость движения автомобиля',
    unit: UNITS.speed,
    type: FIELD_TYPE.d,
    dependsOnTime: true,
  },
  { key: STATE.IG, title: 'Состояние силового агрегата', type: FIELD_TYPE.s, visible: () => true },
  {
    key: STATE.MIL,
    title: 'Статус лампочки MIL',
    type: FIELD_TYPE.s,
    indicator: v => (parseInt(v) ? 'var(--green)' : 'var(--grey)'),
    mapper: v => MIL_ENUM[v],
    lineBreak: true,
    visible: optionValues => isHasOption(optionValues, 'hasCheckEngine'),
    dependsOnTime: false,
  },
  {
    key: STATE.FL,
    title: 'Запас топлива',
    unit: UNITS.volume,
    type: FIELD_TYPE.s,
    progress: getFuelLevelPercent,
    visible: oV => isOptionEnable(oV, 'hasFuelLevelData'),
    mapper: v => (v && !isNaN(v) ? v.toFixed(1) : EMPTY),
    dependsOnTime: false,
    lineBreak: true,
  },
  {
    key: STATE.ST,
    title: 'Статус устройства',
    type: FIELD_TYPE.s,
    mapper: v => v && v.toLowerCase(),
    lineBreak: true,
    dependsOnTime: false,
  },
  {
    key: STATE.LA,
    title: 'Последние данные',
    type: FIELD_TYPE.s,
    mapper: v => parseDate(addUTCmark(v)),
    lineBreak: true,
    dependsOnTime: false,
  },
  {
    key: STATE.BV,
    title: 'Напряжение бортовой сети',
    type: FIELD_TYPE.s,
    unit: UNITS.voltage,
    lineBreak: true,
    dependsOnTime: true,
    mapper: v => (v && !isNaN(v) ? v.toFixed(1) : EMPTY),
  },
];
const EXTRA_FIELDS = [
  {
    key: STATE.APP,
    title: 'Положение педали акселератора',
    type: FIELD_TYPE.d,
    unit: UNITS.percent,
    progress: v => [parseInt(v), parseInt(v)],
    dependsOnTime: true,
    visible: oV => isOptionEnable(oV, 'hasAccelPedalPositionData'),
  },
  {
    key: STATE.ECT,
    title: 'Температура охлаждающей жидкости',
    type: FIELD_TYPE.s,
    unit: '° С',
    mapper: v => (v !== -40 ? v : EMPTY),
    dependsOnTime: true,
    visible: oV => isOptionEnable(oV, 'hasEngineCoolantTemperatureData'),
  },
  {
    key: STATE.BPP,
    title: 'Положение педали тормоза',
    type: FIELD_TYPE.s,
    indicator: v => (parseInt(v) ? 'var(--main-color)' : 'var(--grey)'),
    mapper: v => PEDAL_ENUM[v],
    lineBreak: true,
    dependsOnTime: true,
    visible: oV => isOptionEnable(oV, 'hasBrakePedalPositionData'),
  },
  {
    key: STATE.FR,
    type: FIELD_TYPE.s,
    title: 'Мгновенный расход топлива',
    //TODO: Изменить в библиотеке л/ч на л/час
    unit: 'л/час',
    visible: optionValues => isOptionEnable(optionValues, 'hasFuelRateData'),
    mapper: v => v,
    lineBreak: true,
    dependsOnTime: true,
  },
  {
    key: STATE.K,
    type: FIELD_TYPE.s,
    title: 'Положение ключа в замке',
    visible: optionValues => isOptionEnable(optionValues, 'hasKeyData'),
    mapper: v => KEY_ENUM[v] || EMPTY,
    lineBreak: true,
    dependsOnTime: true,
  },
  {
    key: STATE.AAT,
    type: FIELD_TYPE.s,
    title: 'Температура воздуха за кабиной',
    visible: optionValues => isOptionEnable(optionValues, 'hasAmbientAirTemperatureData'),
    //TODO: Изменить в библиотеке С на ° С
    unit: '° С',
    dependsOnTime: true,
  },
  {
    key: STATE.SRL,
    type: FIELD_TYPE.s,
    title: 'Состав SCR фильтра',
    visible: optionValues => isOptionEnable(optionValues, 'hasSCRData'),
    unit: UNITS.percent,
    lineBreak: true,
    dependsOnTime: true,
  },
  {
    key: STATE.SRT,
    type: FIELD_TYPE.s,
    title: 'Температура SCR фильтра',
    visible: optionValues => isOptionEnable(optionValues, 'hasSCRData'),
    //TODO: Изменить в библиотеке С на ° С
    unit: '° С',
    dependsOnTime: true,
  },
];
const LIGHT_FIELDS = [
  {
    key: STATE.MBS,
    title: 'Статус фар ближнего света',
    type: FIELD_TYPE.s,
    indicator: v => (parseInt(v) ? 'var(--green)' : 'var(--grey)'),
    mapper: v => LIGHT_ENUM[v],
    lineBreak: true,
    dependsOnTime: true,
    visible: optionValues => isOptionEnable(optionValues, 'hasMainBeamStatusData'),
  },
  {
    key: STATE.PLS,
    title: 'Статус габаритов',
    type: FIELD_TYPE.s,
    mapper: v => LIGHT_ENUM[v],
    lineBreak: true,
    indicator: v => (parseInt(v) ? 'var(--green)' : 'var(--grey)'),
    dependsOnTime: true,
    visible: optionValues => isOptionEnable(optionValues, 'hasPositionLightsStatusData'),
  },
  {
    key: STATE.HBS,
    title: 'Статус фар дальнего света',
    type: FIELD_TYPE.s,
    mapper: v => LIGHT_ENUM[v],
    lineBreak: true,
    indicator: v => (parseInt(v) ? 'var(--green)' : 'var(--grey)'),
    dependsOnTime: true,
    visible: optionValues => isOptionEnable(optionValues, 'hasHighBeamStatusData'),
  },
];
const DOORS_FIELDS = [
  {
    key: STATE.DD,
    title: 'Статус двери водителя',
    type: FIELD_TYPE.s,
    mapper: v => processDoors(v),
    lineBreak: true,
    indicator: v => (v ? 'var(--main-color)' : 'var(--grey)'),
    dependsOnTime: true,
    visible: optionValues => isOptionEnable(optionValues, 'hasDriverDoorData'),
  },
  {
    key: STATE.PD,
    title: 'Статус двери пассажира',
    type: FIELD_TYPE.s,
    mapper: v => processDoors(v),
    lineBreak: true,
    indicator: v => (v ? 'var(--main-color)' : 'var(--grey)'),
    dependsOnTime: true,
    visible: optionValues => isOptionEnable(optionValues, 'hasPassDoorData'),
  },
];
const DPF_FIELDS = [
  {
    key: STATE.DCL,
    type: FIELD_TYPE.s,
    mapper: v => DCL_ENUM[v] || EMPTY,
    dependsOnTime: true,
  },
  {
    key: STATE.DTL,
    title: 'Температура прожига',
    type: FIELD_TYPE.s,
    mapper: v => DTL_ENUM(v),
    lineBreak: true,
    dependsOnTime: true,
  },
  {
    key: STATE.DRIS,
    title: 'Блокировка прожига',
    type: FIELD_TYPE.s,
    mapper: v => DRIS_ENUM(v),
    lineBreak: true,
    dependsOnTime: true,
  },
  {
    key: STATE.DP,
    title: 'Давление в фильтре',
    //TODO: Добавить в библиотеку Па
    unit: 'Па',
    type: FIELD_TYPE.s,
    lineBreak: true,
    dependsOnTime: true,
  },
  {
    key: STATE.SL,
    title: 'Масса сажи',
    //TODO: Добавить в библиотеку г
    unit: 'г',
    type: FIELD_TYPE.s,
    lineBreak: true,
    dependsOnTime: true,
  },
];
export const FIELDS_BLOCKS = {
  main: { title: 'Основные данные', fields: MAIN_FIELDS, visible: () => true },
  extra: { title: 'Дополнительно', fields: EXTRA_FIELDS, visible: () => true },
  light: { title: 'Свет', fields: LIGHT_FIELDS, visible: () => true },
  doors: { title: 'Двери', fields: DOORS_FIELDS, visible: () => true },
  dpf: {
    title: 'Данные сажевого фильтра',
    fields: DPF_FIELDS,
    visible: optionValues => isOptionEnable(optionValues, 'hasDPFInfoData'),
  },
};

export const INITIAL_VEHICLE_STATE = (data = {}) => ({
  [STATE.ST]: VEHICLE_STATUS.offline,
  [STATE.LA]: undefined,
  [STATE.IG]: undefined,
  [STATE.SP]: undefined,
  [STATE.FL]: undefined,
  [STATE.OD]: undefined,
  [STATE.ECT]: undefined,
  [STATE.BV]: undefined,
  ...data,
});

export const processRTM = (data = {}) => {
  const { receivedAt, powerPackStatus, speed } = data;
  const { moving, parking } = VEHICLE_STATUS;
  return {
    ...data,
    [STATE.ST]: speed > 0 ? moving : parking,
    [STATE.LA]: receivedAt,
    [STATE.IG]: PPS_ENUM[powerPackStatus],
  };
};
export const processState = data => {
  const { powerPackStatus, speed, isOnline } = data || {};
  const { offline, moving, parking } = VEHICLE_STATUS;
  return {
    ...data,
    [STATE.ST]: !isOnline ? offline : speed > 0 ? moving : parking,
    [STATE.IG]: PPS_ENUM[powerPackStatus],
  };
};
const processDoors = v => (typeof v === 'undefined' ? EMPTY : v ? 'открыта' : 'закрыта');

const PPS_ENUM = {
  0: 'выключено зажигание',
  1: 'включено зажигание, выключен двигатель',
  2: 'заведен двигатель',
  3: 'заведен двигатель',
};
export const EPISODES_ENUM = {
  moving: 'moving',
  stop: 'stop',
  parking: 'parking',
  unknown: 'unknown',
};
export const FUEL_SOURCE_ENUM = {
  l: 'CanLiters',
  p: 'CanPercent',
};
export const EPISODE_TYPE_NAME = {
  [EPISODES_ENUM.moving]: 'Движение',
  [EPISODES_ENUM.stop]: 'Стоянка',
  [EPISODES_ENUM.parking]: 'Остановка',
  [EPISODES_ENUM.unknown]: 'Неопределенность',
};
const MIL_ENUM = {
  0: 'выключена',
  1: 'включена',
};
const LIGHT_ENUM = {
  0: 'выключены',
  1: 'включены',
};
const PEDAL_ENUM = {
  0: 'отпущена',
  1: 'нажата',
};
const KEY_ENUM = {
  0: 'вынут',
  1: 'вставлен',
};
export const DCL_ENUM = {
  0: 'Прожиг не требуется',
  1: 'Необходим прожиг сажевого фильтра',
  2: 'Необходим прожиг сажевого фильтра',
};
const DTL_ENUM = v => (v ? 'достигнута' : 'не достигнута');
const DRIS_ENUM = v => (v ? 'активна' : 'не активна');

export const getCoordinates = state => {
  const { lat, lon } = state || {};
  return [lat, lon];
};

export const getDuration = date => {
  const from = new Date(date + 'Z');
  if (!isValid(from)) return '';
  const now = new Date();
  const days = differenceInDays(now, from);
  const hours = differenceInHours(now, from);
  const minutes = differenceInMinutes(now, from);
  return `${days} д. ${hours - days * 24} ч. ${minutes - hours * 60} мин`;
};
// OLD FIELDS
// {
//   key: STATE.EL,
//   title: 'Нагрузка двигателя на текущих оборотах',
//   unit: UNITS.percent,
//   type: FIELD_TYPE.d,
//   visible: optionValues => isHasOption(optionValues, 'hasEngineLoadData'),
//   dependsOnTime: true,
// },
// {
//   key: STATE.BV,
//   title: 'Напряжение бортовой сети',
//   type: FIELD_TYPE.s,
//   unit: UNITS.voltage,
//   mapper: v => (v && !isNaN(v) ? v.toFixed(1) : EMPTY),
//   dependsOnTime: true,
// },
// {
//   key: STATE.СPP,
//   title: 'Положение педали сцепления',
//   type: FIELD_TYPE.s,
//   indicator: v => (parseInt(v) ? 'var(--main-color)' : 'var(--grey)'),
//   visible: optionValues => isHasOption(optionValues, 'hasClutchPedalPositionData'),
//   mapper: v => PEDAL_ENUM[v],
// lineBreak: true,
//   dependsOnTime: true,
// },
// {
//   key: STATE.FDD,
//   title: 'Запас хода',
//   unit: UNITS.mileage,
//   type: FIELD_TYPE.d,
//   progress: getFuelLevelPercent,
//   visible: oV => isOptionEnable(oV, 'hasFuelLevelData') && isOptionEnable(oV, 'hasFuelDteData'),
//   dependsOnTime: false,
// },
// {
//   key: STATE.TFU,
//   type: FIELD_TYPE.s,
//   title: 'Суммарный расход топлива',
//   unit: UNITS.volume,
//   visible: optionValues => isOptionEnable(optionValues, 'hasTotalFuelUsedData'),
//   dependsOnTime: true,
// },
// {
//   key: STATE.FA,
//   type: FIELD_TYPE.s,
//   title: 'Средний расход топлива',
//   visible: optionValues => isOptionEnable(optionValues, 'hasFuelAfeData'),
//   unit: UNITS.volume,
//   dependsOnTime: true,
// },
