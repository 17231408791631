import { useEffect, useRef } from 'react';
import ReactDOMServer from 'react-dom/server';
import L from 'leaflet';
import { useMap } from 'react-leaflet';
import PropTypes from 'prop-types';
import { EPISODES_ENUM } from 'components/VehiclePage/helpers';
import { reverseCoords } from '@bs/techconnect-ui';

import styles from '../VehiclePageHistory.module.css';

const icon = type =>
  L.divIcon({
    html: ReactDOMServer.renderToStaticMarkup(<div className={styles['map-marker']}>{type[0]?.toUpperCase()}</div>),
    iconSize: L.point(24, 24, true),
  });

export const VehiclePageRoute = ({ episodes }) => {
  const map = useMap();
  const routeRef = useRef(null);

  useEffect(() => {
    if (routeRef.current) {
      map.removeLayer(routeRef.current);
      routeRef.current = null;
    }
    if (episodes.length) {
      const features = [];
      episodes.forEach((ep, index, array) => {
        const { type, start, end, points } = ep || {};
        const { stop, parking, moving, unknown } = EPISODES_ENUM;

        //Точки остановки и парковки
        if (type === stop || type === parking) {
          const { position } = start || {};
          features.push(L.marker(reverseCoords(position), { icon: icon(type) }));
          //Начало остановки соединяется со следующим отрезком пунктиром
          const nextEpisode = array[index + 1];
          if (nextEpisode) {
            const { position: endPoint } = nextEpisode?.end || {};
            features.push(
              L.polyline([reverseCoords(position), reverseCoords(endPoint)], {
                color: 'var(--main-color)',
                dashArray: '10 20',
              }),
            );
          }
        }
        //Линия движения
        if (type === moving) {
          const coords = points.map(({ position }) => reverseCoords(position));
          features.push(L.polyline(coords, { color: 'var(--main-color)' }));
        }
        //Линия неопреденного отрезка
        if (type === unknown) {
          const { position: startPoint } = start || {};
          const { position: endPoint } = end || {};
          features.push(
            L.polyline([reverseCoords(startPoint), reverseCoords(endPoint)], {
              color: 'var(--main-color)',
              dashArray: '10 20',
            }),
          );
        }
      });

      routeRef.current = L.featureGroup(features).addTo(map);
      const bounds = routeRef.current.getBounds();
      if (bounds.isValid()) map.fitBounds(bounds);
    }
  }, [map, episodes]);

  return null;
};

VehiclePageRoute.defaultProps = {
  episodes: [],
};

VehiclePageRoute.propTypes = {
  episodes: PropTypes.arrayOf(PropTypes.object),
};
