import { useState, useCallback } from 'react';
import { Area, AreaChart, ResponsiveContainer, XAxis, YAxis } from 'recharts';

import { reportsService } from 'services';
import { CE_DATA_KEYS, CE_DATA_TITLES, mapCheckEngineDataCharts } from './helpers';
import { ReportsFilters } from '../ReportsFilters/ReportsFilters';
import { ReportsPreset } from '../ReportsPreset/ReportsPreset';
import { Loader, Plate } from '@bs/techconnect-ui';

import styles from './CheckEngine.module.css';

export const CheckEngine = () => {
  const [reports, setReports] = useState(false);
  const [loading, setLoading] = useState(false);

  const fetchCheckEngine = useCallback(filters => {
    const { VC } = CE_DATA_KEYS;
    setLoading(true);
    reportsService
      .getCheckEngine({ ...filters })
      .then(({ status, data }) => {
        if (status === 200) {
          setReports({ ...data, [VC]: data[VC].toLocaleString('ru-RU') });
        } else setReports(false);
      })
      .finally(() => setLoading(false));
  }, []);

  return (
    <>
      <ReportsFilters title="Количество транспортных средств с Check Engine" updateList={fetchCheckEngine} />
      {loading && <Loader />}
      {!loading && !reports && <ReportsPreset />}
      {!loading && reports && (
        <ResponsiveContainer width="100%" height="60%">
          <AreaChart width="100%" height={340} data={mapCheckEngineDataCharts(reports)}>
            <defs>
              <linearGradient id="colorBF" x1="0" y1="0" x2="0" y2="1">
                <stop offset="0%" stopColor="var(--main-color)" stopOpacity={0.2} />
                <stop offset="55%" stopColor="var(--main-color)" stopOpacity={0.01} />
              </linearGradient>
            </defs>
            <XAxis
              dataKey="name"
              type="number"
              domain={[0, 'dataMax + 1000']}
              tickLine={false}
              label={{ value: 'km', position: 'insideBottomRight', offset: -2 }}
            />
            <YAxis dataKey="vehicleCheckEngineCount" ticks={[10, 20, 30, 40]} tickLine={false}></YAxis>
            <Area
              type="monotone"
              dataKey="vehicleCheckEngineCount"
              stackId="1"
              stroke="var(--main-color)"
              fill="url(#colorBF)"
              fillOpacity={1}
            />
            <foreignObject width="100%" height="100%">
              <div className={styles['plates-wrapper']}>
                {Object.values(CE_DATA_KEYS).map(value => (
                  <Plate key={value} title={CE_DATA_TITLES[value]} value={reports[value]} />
                ))}
              </div>
            </foreignObject>
          </AreaChart>
        </ResponsiveContainer>
      )}
    </>
  );
};
