import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Controller, useForm } from 'react-hook-form';

import { newsService } from 'services';
import { NEWS_FORM, PUSH_TYPE_LIST } from './helpers';

import { Input, ButtonTypes, Button, Select, Modal, Checkbox, updateFormErrors, ERRORS } from '@bs/techconnect-ui';
import { ReactComponent as Close } from 'icons/cross.svg';

import styles from './News.module.css';

export const NewsCreate = ({ news, open, setOpen, updateList }) => {
  const [loading, setLoading] = useState(false);
  const [sent, setSent] = useState(false);
  const [checked, setChecked] = useState(false);
  const {
    reset,
    setError,
    control,
    register,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: 'onTouched' });

  const close = () => {
    reset({});
    setOpen();
  };
  const isPushSendDelay = watch('sendOnCreate') === 'delay';

  useEffect(() => {
    if (typeof news === 'object') {
      newsService.getNewsById(news.id).then(({ status, data }) => {
        if (status === 200) {
          const { text, enabled, sendAt, sent } = data.notification;
          setSent(sent);
          setChecked(false);
          reset({ ...data, text, enabled, sendAt });
        }
      });
    }
  }, [news, reset]);

  const create = form => {
    const { title, shortDescription, description, text, sendAt, id } = form;
    const method = id ? newsService.updateNews : newsService.createNews;
    const body = {
      title,
      shortDescription,
      description,
      notification: {
        enabled: checked,
        text,
        sendOnCreate: !isPushSendDelay,
        sendAt,
      },
    };
    setLoading(true);
    method(body, id)
      .then(({ data, status }) => {
        if (status !== 200) updateFormErrors(data, form, setError);
        if (status === 200) {
          updateList();
          close();
        }
      })
      .finally(() => setLoading(false));
  };

  return (
    <Modal open={open} className={styles['create-wrapper']}>
      <h3>{news?.id ? 'Редактирование' : 'Создание'} новости</h3>
      <Button variant={ButtonTypes.T} className={styles['create-close']} onClick={close}>
        <Close />
      </Button>
      {NEWS_FORM.map(({ id, title, options }) => (
        <Input
          key={id}
          placeholder={title}
          label={news?.id && title}
          className={styles['create-field']}
          register={register(id, options)}
          error={errors[id]}
          disabled={loading}
        />
      ))}
      <Checkbox
        className={styles['create-field']}
        label="отправить push-уведомление для новости"
        checked={checked}
        onChange={() => setChecked(!checked)}
        register={register('enabled')}
        disabled={sent}
      />
      {checked && (
        <>
          <Input
            label={'текст уведомления'}
            className={styles['create-field']}
            register={register('text', { required: ERRORS.REQUIRED('текст уведомления') })}
            error={errors.text}
            disabled={loading}
          />
          <Controller
            control={control}
            name="sendOnCreate"
            rules={{ required: ERRORS.REQUIRED('время отправки push-уведомления') }}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
              <Select
                className={styles['create-field']}
                placeholder="время отправки push-уведомления"
                list={PUSH_TYPE_LIST}
                selected={value}
                onSelect={onChange}
                onBlur={onBlur}
                label={'время отправки push-уведомления'}
                error={error}
                disabled={loading}
              />
            )}
          />
          {isPushSendDelay && (
            <Input
              placeholder={'время отправки уведомления'}
              className={styles['create-field']}
              register={register('sendAt', { required: ERRORS.REQUIRED('время отправки уведомления') })}
              error={errors.sendAt}
              type={'datetime-local'}
              disabled={loading}
            />
          )}
        </>
      )}
      <footer>
        <Button onClick={() => handleSubmit(create)()}>{news.id ? 'Редактировать' : 'Создать'}</Button>
      </footer>
    </Modal>
  );
};

NewsCreate.defaultProps = {
  news: {},
  open: false,
  setOpen: () => {},
  updateList: () => {},
};

NewsCreate.propTypes = {
  news: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  updateList: PropTypes.func,
};
