import { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { dealerService } from 'services';
import { COLUMNS } from './helpers';

import { ButtonTypes, Button, Paper, Table, Popper, pageSize } from '@bs/techconnect-ui';
import { DealersForm } from './DealersForm';
import { ReactComponent as Edit } from 'icons/edit.svg';
import { ReactComponent as Delete } from 'icons/delete.svg';
import { ReactComponent as Add } from 'icons/plus.svg';
import { ConfirmModal } from 'components/ConfirmModal/ConfirmModal';

import styles from './Dealers.module.css';

const Dealers = () => {
  const [searchParams] = useSearchParams();
  const page = searchParams.get('page') || 1;

  const [loading, setLoading] = useState(true);
  const [rows, setRows] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  const fetchDealers = useCallback(() => {
    setLoading(true);
    dealerService
      .getDealers({ page, pageSize })
      .then(({ status, data }) => {
        if (status === 200 && Array.isArray(data?.items)) {
          setRows(data.items);
          if (!isNaN(data.totalPages)) setTotalPages(data.totalPages);
        }
      })
      .finally(() => setLoading(false));
  }, [page]);

  useEffect(() => {
    fetchDealers();
  }, [fetchDealers]);

  const handler = row => ({
    ...row,
    edit: (
      <Popper popup="Редактировать">
        <Button
          variant={ButtonTypes.TL}
          onClick={() => {
            setEditModal(row);
          }}
        >
          <Edit />
        </Button>
      </Popper>
    ),
    delete: (
      <Popper popup="Удалить">
        <Button variant={ButtonTypes.TL} onClick={() => setDeleteModal(row.id)}>
          <Delete />
        </Button>
      </Popper>
    ),
  });

  return (
    <>
      <Paper className={styles['filters-wrapper']}>
        <header>
          <span>Дилерские центры</span>

          <Popper popup="Создать объект">
            <Button variant={ButtonTypes.S} onClick={() => setEditModal(!editModal)}>
              <Add />
            </Button>
          </Popper>
        </header>
      </Paper>
      <div className={styles['body']}>
        <Table
          className={styles['table']}
          columns={COLUMNS}
          rows={rows}
          totalPages={totalPages}
          idKey="id"
          handler={handler}
          loading={loading}
        />
        <DealersForm
          dealer={editModal}
          open={!!editModal}
          setOpen={() => setEditModal(!editModal)}
          updateList={fetchDealers}
        />
      </div>

      <ConfirmModal
        open={deleteModal}
        updateList={fetchDealers}
        setOpen={() => setDeleteModal(!deleteModal)}
        method={dealerService.deleteDealer}
        header="Удалить дилерский центр?"
        description="Я понимаю, что восстановить дилерский центр будет невозможно"
      />
    </>
  );
};

export default Dealers;
