import { checkCoords } from '@bs/techconnect-ui';

function wrap180(degrees) {
  if (-180 < degrees && degrees <= 180) return degrees; // avoid rounding due to arithmetic ops if within range
  return ((degrees + 540) % 360) - 180; // sawtooth wave p:180, a:±180
}

function wrap90(degrees) {
  if (-90 <= degrees && degrees <= 90) return degrees; // avoid rounding due to arithmetic ops if within range
  return Math.abs((((degrees % 360) + 270) % 360) - 180) - 90; // triangle wave p:360 a:±90 : fix e.g. -315°
}

function wrap360(degrees) {
  if (0 <= degrees && degrees < 360) return degrees; // avoid rounding due to arithmetic ops if within range
  return ((degrees % 360) + 360) % 360; // sawtooth wave p:360, a:360
}

function toRadians(degree) {
  return (degree * Math.PI) / 180;
}

function toDegrees(radians) {
  return (radians * 180) / Math.PI;
}

function equals(point, nextPoint) {
  if (Math.abs(wrap90(point[0]) - wrap90(nextPoint[0])) > Number.EPSILON) return false;
  return Math.abs(wrap180(point[1]) - wrap180(nextPoint[1])) <= Number.EPSILON;
}

export function calculateHeading(point, nextPoint, heading) {
  // tanθ = sinΔλ⋅cosφ2 / cosφ1⋅sinφ2 − sinφ1⋅cosφ2⋅cosΔλ
  // see mathforum.org/library/drmath/view/55417.html for derivation

  if (!checkCoords(point) || !checkCoords(nextPoint)) return heading;
  if (equals(point, nextPoint)) return heading;

  const φ1 = toRadians(wrap90(point[0]));
  const φ2 = toRadians(wrap90(nextPoint[0]));
  const Δλ = toRadians(wrap180(nextPoint[1]) - wrap180(point[1]));

  const x = Math.cos(φ1) * Math.sin(φ2) - Math.sin(φ1) * Math.cos(φ2) * Math.cos(Δλ);
  const y = Math.sin(Δλ) * Math.cos(φ2);
  const θ = Math.atan2(y, x);

  const bearing = toDegrees(θ);

  return wrap360(bearing);
}
