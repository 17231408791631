import { createBaseApi, configRequest } from './baseAPI';

const baseApi = createBaseApi();

export const vehicleService = {
  createVehicleByDealer,
  checkVehicle,
  getVehicles,
  getMobileVehicles,
  getVehicle,
  getMobileVehicle,
  getMaintenances,
  createMaintenance,
  deleteMaintenances,
};

function createVehicleByDealer(body) {
  return baseApi.post('/dealers/vehicles', body, configRequest);
}

function checkVehicle(vin) {
  return baseApi.get(`/dealers/vehicles/${vin}`, configRequest);
}

function getVehicles(params = {}) {
  const query = [];
  Object.keys(params).forEach(key => params[key] && query.push([key, encodeURIComponent(params[key])].join('=')));
  return baseApi.get(`/vehicles?${query.join('&')}`, configRequest);
}

function getMobileVehicles(params = {}) {
  const query = [];
  Object.keys(params).forEach(key => params[key] && query.push([key, encodeURIComponent(params[key])].join('=')));
  return baseApi.get(`/mobile/vehicles?${query.join('&')}`, configRequest);
}

function getVehicle(vehicleId) {
  return baseApi.get(`/vehicles/${vehicleId}`, configRequest);
}

function getMobileVehicle(vehicleId) {
  return baseApi.get(`/mobile/vehicles/${vehicleId}`, configRequest);
}

function getMaintenances(vehicleId) {
  return baseApi.get(`/vehicles/${vehicleId}/maintenances`, configRequest);
}

function createMaintenance(body, vehicleId) {
  return baseApi.post(`/vehicles/${vehicleId}/maintenances`, body, configRequest);
}

function deleteMaintenances(id) {
  return baseApi.delete(`/maintenances/${id}`, configRequest);
}
