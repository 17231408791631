import { NavLink, Outlet, useLocation } from 'react-router-dom';
import { REPORTS } from './helpers';

import styles from './Reports.module.css';

const Reports = () => {
  const { pathname } = useLocation();
  const active = pathname.split('/')[2] || Object.keys(REPORTS)[0];

  return (
    <div className={styles['wrapper']}>
      <div className={styles['list']}>
        {Object.keys(REPORTS).map(key => (
          <NavLink
            key={key}
            to={key}
            className={() => (active === key ? styles['list-item__active'] : styles['list-item'])}
          >
            {REPORTS[key]}
          </NavLink>
        ))}
      </div>

      <div className={styles['body']}>
        <Outlet />
      </div>
    </div>
  );
};

export default Reports;
