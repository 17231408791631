import { Navigate } from 'react-router-dom';
import { useAuth } from 'react-oidc-context';
import { Loader } from '@bs/techconnect-ui';

export const Logout = () => {
  const { isLoading } = useAuth();

  if (isLoading) return <Loader />;

  return <Navigate to="/login" />;
};
