import { trimObject } from '@bs/techconnect-ui';
import { createBaseApi, configRequest, prepareQuery } from './baseAPI';

const baseApi = createBaseApi();

export const modelBrandService = {
  getBrands,
  getModels,
  getModelsByBrand,
  updateModel,
};

function getBrands(params = {}) {
  return baseApi.get(`/brands?${prepareQuery(params)}`, configRequest);
}
function getModels() {
  return baseApi.get('/models', configRequest);
}
function getModelsByBrand(brandId) {
  return baseApi.get(`/brands/${brandId}/models`, configRequest);
}
function updateModel(body, id) {
  return baseApi.put(`/models/${id}`, trimObject(body), configRequest);
}
