import { useState } from 'react';
import { useAuth } from 'react-oidc-context';

import { userService } from 'services';
import { useUser } from 'helpers';
import { Button, ButtonTypes, Popper } from '@bs/techconnect-ui';
import { ConfirmModal } from 'components/ConfirmModal/ConfirmModal';
import { ReactComponent as Exit } from 'icons/exit.svg';
import { ReactComponent as ResetPass } from 'icons/lock_reset.svg';

import styles from './Header.module.css';

const Header = () => {
  const { clearStaleState, removeUser, signoutRedirect, user: oidcUser } = useAuth();
  const { user } = useUser();
  const [resetEmail, setResetEmail] = useState('');

  const signout = () => {
    const { id_token } = oidcUser || {};
    clearStaleState();
    removeUser();
    signoutRedirect({ id_token_hint: id_token });
  };

  return (
    <header className={styles['wrapper']}>
      <header>
        techconnect
        <b>&nbsp;OEM solution</b>
      </header>

      <div className={styles['profile']}>
        <Popper popup="Сбросить пароль">
          <Button variant={ButtonTypes.T} onClick={() => setResetEmail(user?.email)}>
            <ResetPass />
          </Button>
        </Popper>

        <span className={styles['profile-link']}>{user.email}</span>

        <Popper popup="Выйти из аккаунта">
          <Button variant={ButtonTypes.T} onClick={signout}>
            <Exit />
          </Button>
        </Popper>
      </div>

      <ConfirmModal
        open={!!resetEmail}
        setOpen={() => setResetEmail(false)}
        header="Вы уверенны, что хотите сбросить пароль?"
        description="На email придет сгененрированный одноразовый пароль"
        withoutConfirm
        submit="Сбросить"
        method={() => userService.resetUserPassword({ email: resetEmail })}
      />
    </header>
  );
};

export default Header;
