import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';

import { modelBrandService } from 'services';
import { Input, InputTypes, Button, Loader, updateFormErrors, ERRORS } from '@bs/techconnect-ui';

import styles from './Maintenance.module.css';

export const MaintenanceForm = ({ id, maintenance, updateModels, ...other }) => {
  const {
    reset,
    register,
    handleSubmit,
    setError,
    formState: { errors, isValid },
  } = useForm({ mode: 'onTouched', defaultValues: maintenance });

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    reset(maintenance);
  }, [id, maintenance, reset]);

  const save = form => {
    const maintenance = { mileage: +form.mileage, period: +form.period };
    setLoading(true);
    modelBrandService
      .updateModel({ maintenance, ...other }, id)
      .then(({ data, status }) => {
        if (status !== 200) updateFormErrors(data.maintenance, form, setError);
        if (status === 200) updateModels(other.brandId);
      })
      .finally(() => setLoading(false));
  };

  if (!id) return <div className={styles['form-wrapper']} />;

  return (
    <form className={styles['form-wrapper']} onSubmit={handleSubmit(save)}>
      <div className={styles['form-row']}>
        <Input
          className={styles['form-field']}
          variant={InputTypes.L}
          type="number"
          label="Периодичность ТО по пробегу(км)"
          register={register('mileage', {
            required: ERRORS.REQUIRED('Периодичность ТО по пробегу'),
            min: { value: 1, message: ERRORS.DURATION(1) },
          })}
          error={errors.mileage}
          disabled={loading}
        />
        <Input
          className={styles['form-field']}
          variant={InputTypes.L}
          type="number"
          label="Периодичность ТО по времени(мес)"
          register={register('period', {
            required: ERRORS.REQUIRED('Периодичность ТО по времени'),
            min: { value: 1, message: ERRORS.DURATION(1) },
          })}
          error={errors.period}
          disabled={loading}
        />
      </div>

      <div className={styles['form-row']}>
        <Button className={styles['form-field']} disabled={!isValid || loading} type="submit">
          Сохранить
        </Button>
        <div className={styles['form-field']} />
      </div>
      {loading && <Loader />}
    </form>
  );
};

MaintenanceForm.defaultProps = {
  id: undefined,
  maintenance: {},
  updateModels: () => {},
};

MaintenanceForm.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  maintenance: PropTypes.shape({
    mileage: PropTypes.number,
    period: PropTypes.number,
  }),
  updateModels: PropTypes.func,
};
