import { createBaseApi, configRequest } from './baseAPI';

const baseApi = createBaseApi();

export const notificationsService = { getSystemAlerts };

function getSystemAlerts(params = {}) {
  const query = [];
  Object.keys(params).forEach(key => params[key] && query.push([key, encodeURIComponent(params[key])].join('=')));
  return baseApi.get(`/systemAlerts?${query.join('&')}`, configRequest);
}
