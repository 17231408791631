import { ERRORS, FIELD_VALIDATION } from '@bs/techconnect-ui';

export const COLUMNS = [
  { key: 'name', title: 'название' },
  { key: 'address', title: 'адрес' },
  { key: 'edit', title: '' },
  { key: 'delete', title: '' },
];

export const DEALERS_FORM = [
  {
    id: 'name',
    title: 'Название',
    options: { required: ERRORS.REQUIRED('название'), validate: v => FIELD_VALIDATION.DURATION(v, 1, 30) },
  },
  { id: 'address', title: 'Адрес', options: { required: ERRORS.REQUIRED('адрес') } },
  { id: 'email', title: 'Email', options: { required: ERRORS.REQUIRED('email'), pattern: FIELD_VALIDATION.EMAIL } },
  { id: 'phone', title: 'Телефон', options: { required: ERRORS.REQUIRED('телефон'), pattern: FIELD_VALIDATION.PHONE } },
  { id: 'description', title: 'Комментарий', options: { validate: v => FIELD_VALIDATION.DURATION(v, 0, 500) } },
];

export const BRAND_MODELS_LIST = [
  { key: 'name', title: '' },
  { key: 'checkBox', title: '' },
];
