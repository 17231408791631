import axios from 'axios';

export function createBaseApi(apiVersion = 'v1', timeout = 0) {
  const baseApi = axios.create({ baseURL: `/api/${apiVersion}` });

  // Обработка ОТВЕТА
  baseApi.interceptors.response.use(
    response => {
      return response;
    },
    (error = {}) => {
      const { response } = error;
      if (response?.status === 401) window.location.pathname = '/signout';
      sessionStorage.setItem('error', JSON.stringify(response));
      //На других вкладках это будет вызывать два вызова события "storage"
      //Проблемой быть не должно
      window.dispatchEvent(new Event('storage'));

      return response;
    },
  );

  // Обработка ЗАПРОСА
  baseApi.interceptors.request.use(
    config => {
      const { access_token } =
        JSON.parse(
          sessionStorage.getItem(
            `oidc.user:${process.env.REACT_APP_AUTH_URL}:${process.env.REACT_APP_IDENTITY_CLIENT_ID}`,
          ),
        ) || {};
      config.headers = {
        ...config.headers,
        Authorization: ['Bearer', access_token].join(' '),
      };
      config.timeout = timeout;
      return config;
    },
    error => {
      console.log(error);
      return Promise.reject(error);
    },
  );

  return baseApi;
}

export const configRequest = createConfig();

function createConfig() {
  return {
    mode: 'cors',
    headers: { 'Content-Type': 'application/json' },
  };
}
export const prepareQuery = params => {
  const query = [];
  Object.keys(params).forEach(key => {
    if (!params[key]) return;
    const value = Array.isArray(params[key]) ? JSON.stringify(params[key]) : params[key];
    query.push([key, encodeURIComponent(value)].join('='));
  });
  return query.join('&');
};
