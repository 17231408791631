import { UNITS } from '@bs/techconnect-ui';

export const TIME_INTERVALS = [
  { title: 'Днем', time: '9:00-18-00' },
  { title: 'Вечером', time: '18:00-21-00' },
  { title: 'Ночью', time: '21:00-6-00' },
  { title: 'Утром', time: '6:00-9-00' },
];

export const MA_DATA_KEYS = {
  VC: 'vehicleCount',
  DAM: 'dayAvgMileage',
  MAM: 'morningAvgMileage',
  AAM: 'afternoonAvgMileage',
  EAM: 'eveningAvgMileage',
  NAM: 'nightAvgMileage',
  DAMD: 'dayAvgMovingDuration',
  MAMD: 'morningAvgMovingDuration',
  AAMD: 'afternoonAvgMovingDuration',
  EAMD: 'eveningAvgMovingDuration',
  NAMD: 'nightAvgMovingDuration',
};

export const mileAgeData = {
  [MA_DATA_KEYS.VC]: 12500,
  [MA_DATA_KEYS.DAM]: 46,
  [MA_DATA_KEYS.MAM]: 15,
  [MA_DATA_KEYS.AAM]: 9,
  [MA_DATA_KEYS.EAM]: 18,
  [MA_DATA_KEYS.NAM]: 4,
  [MA_DATA_KEYS.DAMD]: 124,
  [MA_DATA_KEYS.MAMD]: 45,
  [MA_DATA_KEYS.AAMD]: 15,
  [MA_DATA_KEYS.EAMD]: 50,
  [MA_DATA_KEYS.NAMD]: 14,
};

export const MA_DATA_TITLES = {
  [MA_DATA_KEYS.VC]: 'Автомобилей',
  [MA_DATA_KEYS.DAM]: 'Средний пробег на 1 автомобиль',
};
export const MA_DATA_UNITS = {
  [MA_DATA_KEYS.DAM]: UNITS.mileage,
};

export const MA_BODY = [[MA_DATA_KEYS.AAM], [MA_DATA_KEYS.EAM], [MA_DATA_KEYS.NAM], [MA_DATA_KEYS.MAM]];
export const MAD_BODY = [[MA_DATA_KEYS.AAMD], [MA_DATA_KEYS.EAMD], [MA_DATA_KEYS.NAMD], [MA_DATA_KEYS.MAMD]];

export const REPORT_BODY = [[MA_DATA_KEYS.VC], [MA_DATA_KEYS.DAM]];

export const DEFAULT_DAILY_INTERVALS = {
  morningFrom: '06:00:00',
  afternoonFrom: '09:00:00',
  afternoonTo: '18:00:00',
  eveningFrom: '18:00:00',
  eveningTo: '21:00:00',
  nightFrom: '21:00:00',
  nightTo: '06:00:00',
};
