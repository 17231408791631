export const CE_DATA_KEYS = {
  VC: 'vehicleCount',
  VCETC: 'vehicleCheckEngineTotalCount',
};

export const CE_DATA_TITLES = {
  [CE_DATA_KEYS.VC]: 'Автомобилей',
  [CE_DATA_KEYS.VCETC]: 'Ошибок Check Engine',
};

export const mapCheckEngineDataCharts = (report = {}) => {
  const { items = [] } = report;
  if (!items || items.length === 0) {
    return [];
  }
  const data =
    items.map(({ odometerEnd, vehicleCheckEngineCount }) => {
      return { name: odometerEnd, vehicleCheckEngineCount };
    }) || [];
  // задаем начало первых данных из пакета
  const { odometerStart, vehicleCheckEngineCount } = items[0];
  data.unshift({ name: odometerStart, vehicleCheckEngineCount });

  return data;
};
