import { NavLink } from 'react-router-dom';
import { NAV_MENU, useUser } from 'helpers';
import styles from './Navigate.module.css';

const Navigate = () => {
  const {
    user: { roles = [] },
  } = useUser();

  const abilityFilter = ({ key }) => {
    if (key === '/') return false;
    const { ability = [] } = NAV_MENU.find(item => key === item.key) || {};
    const intersection = ability.filter(r => roles.includes(r));
    return !!intersection.length;
  };

  return (
    <nav className={styles['wrapper']}>
      {NAV_MENU.filter(abilityFilter).map(({ key, title }) => (
        <NavLink key={key} to={key} className={({ isActive }) => (isActive ? styles['item__active'] : styles['item'])}>
          {title}
        </NavLink>
      ))}
    </nav>
  );
};

export default Navigate;
