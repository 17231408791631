import { createBaseApi, configRequest } from './baseAPI';

const baseApi = createBaseApi();

export const telemetryService = { subscribe, unsubscribe };

function subscribe(body) {
  return baseApi.post('/telemetry/subscribe', body, configRequest);
}
function unsubscribe(body) {
  return baseApi.post('/telemetry/unsubscribe', body, configRequest);
}
