import { createBaseApi, configRequest, prepareQuery } from './baseAPI';
import { DrivingStyleData } from 'components/Reports/DrivingStyle/helpers';
import { mileAgeData } from 'components/Reports/DailyMileage/helpers';

const baseApi = createBaseApi();

export const reportsService = {
  getfuelConsumption,
  getCheckEngine,
  getbatteryVoltage,
  getDrivingStyle,
  getDrivingStyleMoc,
  getDailyMileage,
  getDailyMileageMoc,
};

function getfuelConsumption(params = {}) {
  return baseApi.get(`/reports/fuelConsumption?${prepareQuery(params)}`, configRequest);
}

function getCheckEngine(params = {}) {
  return baseApi.get(`/reports/checkEngine?${prepareQuery(params)}`, configRequest);
}

function getbatteryVoltage(params = {}) {
  return baseApi.get(`/reports/batteryVoltage?${prepareQuery(params)}`, configRequest);
}

function getDrivingStyle(params = {}) {
  return baseApi.get(`/reports/drivingStyle?${prepareQuery(params)}`, configRequest);
}

function getDrivingStyleMoc() {
  const data = DrivingStyleData;
  const promise = new Promise(resolve => {
    resolve(data);
  });
  return promise;
}

function getDailyMileage(params = {}) {
  return baseApi.get(`/reports/exploitation?${prepareQuery(params)}`, configRequest);
}

function getDailyMileageMoc() {
  const data = mileAgeData;
  const promise = new Promise(resolve => {
    resolve(data);
  });
  return promise;
}
