import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { dealerService } from 'services';
import { BRAND_MODELS_LIST, DEALERS_FORM } from './helpers';

import { Button, Input, Checkbox, Table, ButtonTypes, updateFormErrors } from '@bs/techconnect-ui';

import styles from './Dealers.module.css';

export const DealersForm = ({ dealer, open, setOpen, updateList }) => {
  const [loading, setLoading] = useState(false);
  const [models, setModels] = useState([]);
  const [checkedModels, setCheckedModels] = useState([]);
  const {
    reset,
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({ mode: 'onTouched' });

  const close = () => {
    reset({});
    setCheckedModels([]);
    setOpen();
  };

  useEffect(() => {
    dealerService.getModels().then(({ status, data }) => {
      if (status === 200) {
        setModels(data.items);
      }
    });
  }, [dealer]);

  useEffect(() => {
    if (dealer.id) {
      dealerService.getDealerById(dealer.id).then(({ status, data }) => {
        if (status === 200) {
          reset(data);
          setCheckedModels(data.models.map(model => model.id));
        }
      });
    } else {
      reset({});
      setCheckedModels([]);
    }
  }, [dealer, reset]);

  const create = form => {
    const { id } = form;
    const body = { ...form, models: checkedModels };
    const method = id ? dealerService.updateDealer : dealerService.createDealer;
    setLoading(true);
    method(body, id)
      .then(({ data, status }) => {
        if (status !== 200) updateFormErrors(data, form, setError);
        if (status === 200) {
          updateList();
          close();
        }
      })
      .finally(() => setLoading(false));
  };

  const isCheckedModel = id => checkedModels.includes(id);
  const handleChange = (id, isChecked) => {
    if (isChecked) {
      setCheckedModels(prevCheckedModels => [...prevCheckedModels, id]);
    } else {
      setCheckedModels(prevCheckedModels => prevCheckedModels.filter(modelId => modelId !== id));
    }
  };

  const handler = row => ({
    ...row,
    name: `${row.brand?.name} ${row.name}`,
    checkBox: (
      <Checkbox
        key={row.id}
        id={`${row.id}`}
        className={styles['create-field']}
        checked={isCheckedModel(row.id)}
        onChange={e => {
          handleChange(row.id, e.target.checked);
        }}
        disabled={loading}
      />
    ),
  });

  if (!open) return <article className={styles['form-wrapper']} />;
  return (
    <article className={styles['form-wrapper']}>
      <header>
        {dealer?.id ? 'Редактирование' : 'Создание'} дилерского центра
        <Button variant={ButtonTypes.R} onClick={() => handleSubmit(create)()}>
          {dealer.id ? 'Редактировать' : 'Создать'}
        </Button>
      </header>
      {DEALERS_FORM.map(({ id, title, options }) => (
        <Input
          className={styles['input-field']}
          key={id}
          placeholder={title}
          label={dealer?.id && title}
          register={register(id, options)}
          error={errors[id]}
          disabled={loading}
          variant={'light'}
        />
      ))}
      <hr />
      <h3>Список доступных моделей</h3>
      <div className={styles['models-box']}>
        <Table
          idKey="id"
          className={styles['models-checkbox-table']}
          columns={BRAND_MODELS_LIST}
          handler={handler}
          rows={models}
        />
      </div>
    </article>
  );
};

DealersForm.defaultProps = {
  dealer: {},
  open: false,
  setOpen: () => {},
  updateList: () => {},
};

DealersForm.propTypes = {
  dealer: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  updateList: PropTypes.func,
};
