import { useEffect, useState } from 'react';
import { MapContainer, TileLayer } from 'react-leaflet';
import PropTypes from 'prop-types';

import { telemetryService } from 'services';
import {
  FIELD_TYPE,
  STATE,
  FIELDS_BLOCKS,
  isActualFullData,
  processState,
  INITIAL_VEHICLE_STATE,
  processRTM,
  getCoordinates,
  isOptionEnable,
  DCL_ENUM,
  getDuration,
} from '../helpers';
import {
  Paper,
  PaperTypes,
  ProgressBar,
  checkCoords,
  ASTANA_COORDS,
  EMPTY,
  TILES_CONFIG,
  useTelemetry,
  UNITS,
} from '@bs/techconnect-ui';
import { VehicleMarker } from './VehicleMarker/VehicleMarker';

import styles from './VehiclePageCurrent.module.css';

export const VehiclePageCurrent = ({ vehicle }) => {
  const { optionValues = [], state: initState, device, vehicleId, particulateFilterOnDate } = vehicle || {};
  const { deviceId } = device || {};
  const [state, setState] = useState(INITIAL_VEHICLE_STATE(processState(initState)));
  const [position, setPositon] = useState(getCoordinates(initState) || []);
  const { stateData } = useTelemetry(deviceId, vehicleId, telemetryService.subscribe, telemetryService.unsubscribe);

  useEffect(() => {
    if (Object.keys(stateData).length) {
      setState(processRTM({ ...state, ...stateData }));
      if (checkCoords(getCoordinates(stateData))) setPositon(getCoordinates(stateData));
    }
    // eslint-disable-next-line
  }, [stateData]);

  const isFullData = isActualFullData(state);
  const getStateValue = ({ key, mapper, unit = '', dependsOnTime }) => {
    if (!isFullData && dependsOnTime) return EMPTY;
    const value = mapper ? mapper(state[key]) : typeof state[key] === 'undefined' ? EMPTY : state[key];
    return unit && value !== EMPTY ? [value, unit].join(' ') : value;
  };

  const renderField = ({
    key,
    title,
    unit,
    mapper,
    type,
    indicator,
    icon,
    progress,
    dependsOnTime,
    lineBreak,
    withoutBar,
  }) => {
    //EXCEPTION FOR MIL INDICATOR
    const { value: hasMIL } = optionValues.find(o => o?.option?.key === 'hasCheckEngine') || {};
    //EMPTY FIELD
    if (type === FIELD_TYPE.e || (key === STATE.MIL && hasMIL !== 'true')) {
      return <div key={key} className={styles[type]} />;
    }
    const value = getStateValue({ key, mapper, unit, dependsOnTime, lineBreak });
    const classes = [styles['widget'], styles[type], styles[key]].join(' ');

    //SINGLE FIELD
    if (type === FIELD_TYPE.s) {
      return (
        <Paper variant={PaperTypes.LM} key={key} className={classes}>
          <span>
            {title}&nbsp;
            {!!lineBreak && <br />}
            <b>{value}</b>
          </span>
          {!!indicator && <div style={{ background: indicator(state[key]) }} className={styles['indicator']} />}
        </Paper>
      );
    }

    //DOUBLE FIELD
    const displayValue =
      value !== EMPTY ? (
        <>
          {progress ? progress(state[key], vehicle)[1] + unit : value}
          {icon}
          <br />
          {!!progress && !withoutBar && <ProgressBar percent={progress(state[key], vehicle)[0]} />}
          {!!progress && withoutBar && <span>{[progress(state[key], vehicle)[0], UNITS.percent].join(' ')}</span>}
        </>
      ) : (
        value
      );

    return (
      <Paper variant={PaperTypes.LM} key={key} className={classes}>
        <div className={styles['double-head']}>{displayValue}</div>
        <div className={styles['double-foot']}>{title}</div>
      </Paper>
    );
  };

  return (
    <>
      {isOptionEnable(optionValues, 'hasDPFInfoData') && isFullData ? (
        <div className={[styles['dpfstatus'], styles['dpfstatus-' + state.dpfCommandLamp]].join(' ')}>
          <span className={styles['dpfstatus-value']}>{DCL_ENUM[state.dpfCommandLamp] || DCL_ENUM[0]}</span>
          <span className={styles['dpfstatus-date']}>{getDuration(particulateFilterOnDate)}</span>
        </div>
      ) : (
        <div className={styles['dpfstatus-empty']} />
      )}
      {Object.keys(FIELDS_BLOCKS)
        .filter(block => FIELDS_BLOCKS[block].visible(optionValues))
        .map(key => {
          // Если нет видимых полей в блоке, не рендерим его
          const fields = FIELDS_BLOCKS[key].fields;
          const hasVisibleField = fields.some(field => !field?.visible || field.visible(optionValues));
          if (!hasVisibleField) {
            return null;
          }
          return (
            <div key={key + 'fields-block'} className={styles[key]}>
              <header className={styles['block-header']}>{FIELDS_BLOCKS[key].title}</header>
              <div className={styles[key + '-body']}>
                {FIELDS_BLOCKS[key].fields
                  .filter(field => !field?.visible || field.visible(optionValues))
                  .map(renderField)}
              </div>
            </div>
          );
        })}
      <Paper className={styles['map']}>
        <MapContainer center={checkCoords(position) ? position : ASTANA_COORDS} zoom={13} zoomControl={false}>
          <TileLayer {...TILES_CONFIG} />
          <VehicleMarker position={position} />
        </MapContainer>
      </Paper>

      <div className={styles['empty']} />
    </>
  );
};

VehiclePageCurrent.defaultProps = {
  vehicle: {
    vehicleId: '',
    device: { deviceId: '' },
    state: {},
  },
};

VehiclePageCurrent.propTypes = {
  vehicle: PropTypes.shape({
    vehicleId: PropTypes.string,
    device: PropTypes.shape({ deviceId: PropTypes.string, esn: PropTypes.string }),
    state: PropTypes.object,
    model: PropTypes.object,
    vin: PropTypes.string,
  }),
};
