import { useMap } from 'react-leaflet';
import { useEffect, useRef, useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import L from 'leaflet';
import PropTypes from 'prop-types';
import { calculateHeading } from './helpers';
import { checkCoords, usePrevious } from '@bs/techconnect-ui';
import { ReactComponent as MarkerSVG } from 'icons/map_arrow.svg';

import styles from './VehicleMarker.module.css';

const icon = L.divIcon({
  html: ReactDOMServer.renderToStaticMarkup(
    <div id="vehicle-marker" className={styles['vehicle-marker']}>
      <MarkerSVG />
    </div>,
  ),
  iconSize: L.point(24, 24, true),
});

export const VehicleMarker = ({ position }) => {
  const map = useMap();
  const markerRef = useRef(null);
  const [adding, setAdding] = useState(false);
  const prevPosition = usePrevious(position);

  useEffect(() => {
    if (checkCoords(position) && !adding) {
      setAdding(true);
      markerRef.current = L.marker(position, { icon }).addTo(map);
      map.flyTo(position);
    }
  }, [adding, map, position]);

  useEffect(() => {
    if (markerRef.current) {
      markerRef.current.setLatLng(position);
      const heading = calculateHeading(position, prevPosition, 0);
      const element = document.getElementById('vehicle-marker');
      element.style.transform = `rotate(${heading + 180}deg)`;
    }
  }, [position, prevPosition]);

  return null;
};

VehicleMarker.defaultProps = { position: [] };
VehicleMarker.propTypes = { position: PropTypes.arrayOf(PropTypes.number) };
