import { modelBrandService } from 'services';
import { FilterTypes, modelsOptionsMapper } from '@bs/techconnect-ui';

export const COLUMNS = [
  { key: 'brandModel', title: 'Модель' },
  { key: 'vin', title: 'VIN' },
  { key: 'plateNumber', title: 'Гос. номер' },
  { key: 'lastActivity', title: 'Последняя активность' },
  { key: 'status', title: 'Статус' },
  { key: 'saleDate', title: 'Дата продажи' },
  { key: 'lastMaintenanceDate', title: 'Дата последнего ТО' },
  { key: 'nextMaintenanceInMileage', title: 'Кол-во км до следующего ТО' },
  { key: 'nextMaintenanceDate', title: 'Дата следующего ТО' },
];

export const VEHICLE_STATUS = {
  offline: 'Оффлайн',
  moving: 'В движении',
  parking: 'На стоянке',
};

export const FILTERS = [
  {
    key: 'modelId',
    title: 'Модель',
    type: FilterTypes.s,
    source: modelBrandService.getModels,
    mapper: modelsOptionsMapper,
  },
  { key: 'vin', title: 'VIN' },
  { key: 'plateNumber', title: 'Гос. Номер' },
];
