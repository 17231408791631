import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'react-oidc-context';
import { Loader } from '@bs/techconnect-ui';

export const Redirect = () => {
  const auth = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (auth.isAuthenticated) {
      navigate('/vehicles');
    }
  }, [auth, navigate]);

  return <Loader />;
};
