import { createBaseApi, configRequest } from './baseAPI';

const baseApi = createBaseApi();

export const userService = { getSelf, resetUserPassword };

function getSelf(body) {
  return baseApi.get('/mobile/users/self', body, configRequest);
}

function resetUserPassword(body) {
  return baseApi.post('/registration/recovery', body, configRequest);
}
