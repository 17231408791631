import { useEffect, useState } from 'react';
import { MapContainer, TileLayer } from 'react-leaflet';
import PropTypes from 'prop-types';

import { EPISODES_ENUM, EPISODE_TYPE_NAME } from '../helpers';
import { routeService } from 'services';
import {
  Loader,
  SelectDate,
  Popper,
  Paper,
  PaperTypes,
  parseDate,
  requestDate,
  UNITS,
  ASTANA_COORDS,
  EMPTY,
  TILES_CONFIG,
} from '@bs/techconnect-ui';
import { VehiclePageRoute } from './VehiclePageRoute/VehiclePageRoute';

import styles from './VehiclePageHistory.module.css';

export const VehiclePageHistory = ({ vehicle }) => {
  const { vehicleId } = vehicle || {};

  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState(new Date());
  const [episodes, setEpisodes] = useState([]);

  useEffect(() => {
    setLoading(true);
    const query = {
      vehicleId,
      from: requestDate({ date, time: '00:00:00' }),
      to: requestDate({ date, time: '23:59:59' }),
      withPoints: true,
    };
    routeService
      .getEpisodes(query)
      .then(({ status, data }) => {
        if (status === 200 && Array.isArray(data?.items)) {
          setEpisodes(data.items);
        }
      })
      .finally(() => setLoading(false));
  }, [date, vehicleId]);

  const renderEpisode = ({ type, mileage, start, end }) => {
    const key = [type, start.date || Math.random()].join('-');
    const startTime = parseDate(start.date, 'HH:mm');
    const endTime = parseDate(end.date, 'HH:mm');
    const isMovingOrUnknown = type === EPISODES_ENUM.moving || type === EPISODES_ENUM.unknown;
    const mileageEl = isMovingOrUnknown && <span>{[mileage, UNITS.mileage].join(' ')}</span>;
    return (
      <Paper variant={PaperTypes.LM} key={key} className={styles['episode']}>
        <div className={styles['episode-row']}>
          <b>{EPISODE_TYPE_NAME[type]}</b>
          {mileageEl}
          {startTime} - {endTime}
        </div>
        <div className={styles['episode-row-address']}>
          <div className={styles['episode-point']} />
          <Popper refCls={styles['episode-address']}>{start.address || EMPTY}</Popper>
        </div>
        {isMovingOrUnknown && (
          <div className={styles['episode-row-address']}>
            <div className={styles['episode-point']}>
              <div className={styles['episode-hr']} />
            </div>
            <Popper refCls={styles['episode-address']}>{end.address || EMPTY}</Popper>
          </div>
        )}
      </Paper>
    );
  };

  return (
    <>
      <div className={styles['episodes']}>
        <SelectDate className={styles['episodes-filter']} date={date} setDate={setDate} maxDate={new Date()} />
        {loading && <Loader />}
        {!loading && <div className={styles['episodes-list']}>{episodes.map(renderEpisode)}</div>}
      </div>

      <Paper className={styles['map']}>
        <MapContainer center={ASTANA_COORDS} zoom={13} zoomControl={false}>
          <TileLayer {...TILES_CONFIG} />
          <VehiclePageRoute episodes={episodes} />
        </MapContainer>
      </Paper>
    </>
  );
};

VehiclePageHistory.defaultProps = {
  vehicle: {
    vehicleId: '',
    device: { deviceId: '' },
    state: {},
  },
};

VehiclePageHistory.propTypes = {
  vehicle: PropTypes.shape({
    vehicleId: PropTypes.string,
    device: PropTypes.shape({ deviceId: PropTypes.string }),
    state: PropTypes.object,
  }),
};
