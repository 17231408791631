import { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import PropTypes from 'prop-types';

import { modelBrandService, vehicleService } from 'services';
import { Button, Input, Paper, Select, updateFormErrors, ERRORS, modelsOptionsMapper } from '@bs/techconnect-ui';

import styles from '../VehicleConnect.module.css';

const FORM = [
  { key: 'vin', label: 'VIN', options: { validate: v => v.length === 17 || ERRORS.LENGTH(17) } },
  { key: 'esn', label: 'ESN' },
  { key: 'phone', label: 'Номер телефона владельца' },
];

const CreateVehicle = ({ resetCheck, handleSubmitCheck }) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    control,
    setError,
  } = useForm({ mode: 'onTouched' });

  const [createFailed, setCreateFailed] = useState(false);

  const create = form => {
    vehicleService.createVehicleByDealer(form).then(({ data, status }) => {
      if (status !== 200) {
        updateFormErrors(data, form, setError);
        setCreateFailed(true);
      }
      if (status === 200) {
        resetCheck({ vin: form.vin });
        handleSubmitCheck();
        setCreateFailed(false);
      }
    });
  };

  return (
    <Paper className={styles['create']}>
      <form onSubmit={handleSubmit(create)}>
        <header>Создание связки VIN - ESN</header>
        <Controller
          control={control}
          name="modelId"
          rules={{ required: ERRORS.REQUIRED('Модель транспортного средства') }}
          render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
            <Select
              className={styles['filters-model']}
              placeholder="Модель транспортного средства"
              source={modelBrandService.getModels}
              mapper={modelsOptionsMapper}
              selected={value}
              onSelect={onChange}
              onBlur={onBlur}
              error={error}
            />
          )}
        />
        {FORM.map(({ key, label, options }) => (
          <Input
            key={key}
            className={styles['field']}
            placeholder={label}
            register={register(key, { required: ERRORS.REQUIRED(label), ...options })}
            error={errors[key]}
          />
        ))}
        <Button disabled={!isValid} className={styles['submit']} type="submit">
          Создать
        </Button>
      </form>

      {createFailed && (
        <div className={styles['create-fail']}>
          <span>Произошла ошибка, свяжитесь со службой поддержки</span>
          <span>
            Email: <a href="mailto: support@technolabs.ru">support@technolabs.ru</a>
          </span>
          <span>
            Telegram:{' '}
            <a target="_blank" rel="noreferrer noopener" href="https://t.me/aconnect_support">
              https://t.me/aconnect_support
            </a>
          </span>
        </div>
      )}
    </Paper>
  );
};

CreateVehicle.defaultProps = { resetCheck: () => {}, handleSubmitCheck: () => {} };
CreateVehicle.propTypes = { resetCheck: PropTypes.func, handleSubmitCheck: PropTypes.func };

export default CreateVehicle;
