import { useState } from 'react';
import PropTypes from 'prop-types';

import { Button, ButtonTypes, Modal, Checkbox, CheckboxTypes } from '@bs/techconnect-ui';

import styles from './ConfirmModal.module.css';

export const ConfirmModal = ({
  open,
  header,
  description,
  submit,
  cancel,
  setOpen,
  updateList,
  method,
  withoutConfirm,
}) => {
  const [loading, setLoading] = useState(false);
  const [confirm, setConfirm] = useState(withoutConfirm);

  const fetch = () => {
    setLoading(true);
    method()
      .then(({ status }) => {
        if (status === 204 || status === 200) {
          updateList();
          close();
        }
      })
      .finally(() => setLoading(false));
  };

  const close = () => {
    setConfirm(false);
    setOpen();
  };

  return (
    <Modal open={open} className={styles['wrapper']}>
      <header>{header}</header>
      {withoutConfirm ? (
        <span className={styles['confirm']}>{description}</span>
      ) : (
        <Checkbox
          variant={CheckboxTypes.SS}
          className={styles['confirm']}
          label={description}
          checked={confirm}
          onChange={() => setConfirm(!confirm)}
        />
      )}
      <footer>
        <Button disabled={!confirm} onClick={fetch} loading={loading}>
          {submit}
        </Button>
        <Button variant={ButtonTypes.N} onClick={close} disabled={loading}>
          {cancel}
        </Button>
      </footer>
    </Modal>
  );
};

ConfirmModal.defaultProps = {
  open: false,
  withoutConfirm: false,
  header: '',
  description: '',
  submit: 'Да, удалить',
  cancel: 'Отмена',
  setOpen: () => {},
  updateList: () => {},
  method: () => {},
};

ConfirmModal.propTypes = {
  open: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  withoutConfirm: PropTypes.bool,
  header: PropTypes.string,
  description: PropTypes.string,
  submit: PropTypes.string,
  cancel: PropTypes.string,
  setOpen: PropTypes.func,
  updateList: PropTypes.func,
  method: PropTypes.func,
};
