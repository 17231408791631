import { useState, useCallback } from 'react';
import { Area, AreaChart, ResponsiveContainer, XAxis, YAxis } from 'recharts';

import { reportsService } from 'services';
import { BD_DATA_KEYS, BD_DATA_TITLES, mapBatteryDataCharts } from './helpers';
import { ReportsFilters } from '../ReportsFilters/ReportsFilters';
import { ReportsPreset } from '../ReportsPreset/ReportsPreset';
import { Loader, Plate } from '@bs/techconnect-ui';

import styles from './BatteryDegradation.module.css';

export const BatteryDegradation = () => {
  const [reports, setReports] = useState(false);
  const [loading, setLoading] = useState(false);

  const fetchBatteryVoltage = useCallback(filters => {
    const { VC } = BD_DATA_KEYS;
    setLoading(true);
    reportsService
      .getbatteryVoltage({ ...filters })
      .then(({ data, status }) => {
        if (status === 200) {
          setReports({ ...data, [VC]: data[VC].toLocaleString('ru-RU') });
        } else setReports(false);
      })
      .finally(() => setLoading(false));
  }, []);

  return (
    <>
      <ReportsFilters title="Деградация аккумулятора от пробега" updateList={fetchBatteryVoltage} />
      {loading && <Loader />}
      {!loading && !reports && <ReportsPreset />}
      {!loading && reports && (
        <>
          <ResponsiveContainer width="100%" height="60%">
            <AreaChart width="100%" height={340} data={mapBatteryDataCharts(reports)} margin={{ top: 15 }}>
              <defs>
                <linearGradient id="colorIdling" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="0%" stopColor="#E7412C" stopOpacity={0.3} />
                  <stop offset="55%" stopColor="#E7412C" stopOpacity={0.01} />
                </linearGradient>
                <linearGradient id="colorMoving" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="0%" stopColor="#00BF58" stopOpacity={0.3} />
                  <stop offset="55%" stopColor="#00BF58" stopOpacity={0.01} />
                </linearGradient>
              </defs>
              <XAxis dataKey="name" tickLine={false} />
              <YAxis
                tickFormatter={number => {
                  return number === 0 ? '' : `${number} Вт`;
                }}
                tickLine={false}
                tickCount={6}
                type="number"
                padding={{ top: 10 }}
              ></YAxis>
              <Area
                type="monotone"
                dataKey="batteryVoltageIdlingAvg"
                stroke="#E7412C"
                fill="url(#colorIdling)"
                fillOpacity={1}
              />
              <Area
                type="monotone"
                dataKey="batteryVoltageMovingAvg"
                stroke="#00BF58"
                fill="url(#colorMoving)"
                fillOpacity={1}
              />
              <foreignObject width="100%" height="100%">
                <div className={styles['label-wrapper']}>
                  <div className={styles['circle-with-label']}>
                    <div className={styles['circle']}></div>
                    <span>Холостой ход</span>
                  </div>
                  <div className={styles['circle-with-label']}>
                    <div className={styles['circle']}></div>
                    <span>В движении</span>
                  </div>
                </div>
              </foreignObject>
            </AreaChart>
          </ResponsiveContainer>
          <div className={styles['plates-wrapper']}>
            <Plate title={BD_DATA_TITLES[BD_DATA_KEYS.VC]} value={reports[BD_DATA_KEYS.VC]} />
          </div>
        </>
      )}
    </>
  );
};
