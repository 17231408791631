import { FilterTypes, UNITS } from '@bs/techconnect-ui';

export const DS_DATA_KEYS = {
  VC: 'vehicleCount',
  LAAC: 'longAccelAvgCount',
  LDAC: 'longDecelAvgCount',
  LADAC: 'latAccelDecelAvgCount',
  AADAC: 'altAccelDecelAvgCount',
  BOP: 'beltOnPercent',
  SOP: 'speedOverPercent',
};
export const DS_DATA_TITLES = {
  [DS_DATA_KEYS.VC]: 'Автомобилей',
  [DS_DATA_KEYS.LAAC]: 'Ускорения на 100 км\n(средний)',
  [DS_DATA_KEYS.LDAC]: 'Резких торможения\nна 100 км (средний)',
  [DS_DATA_KEYS.LADAC]: 'Резких поворотов на\n100 км (средний)',
  [DS_DATA_KEYS.AADAC]: 'Проезда\nпрепятствия на 100 км (средний)',
  [DS_DATA_KEYS.BOP]: 'Времени\nнепристегнут ремень\n(средний)',
  [DS_DATA_KEYS.SOP]: 'Времени\nпревышение\nскорости (средний)',
};
export const DS_DATA_UNITS = {
  [DS_DATA_KEYS.BOP]: UNITS.percent,
  [DS_DATA_KEYS.SOP]: UNITS.percent,
};

export const DrivingStyleData = {
  [DS_DATA_KEYS.VC]: 12560,
  [DS_DATA_KEYS.LAAC]: 3,
  [DS_DATA_KEYS.LDAC]: 5,
  [DS_DATA_KEYS.LADAC]: 12,
  [DS_DATA_KEYS.AADAC]: 2,
  [DS_DATA_KEYS.BOP]: 20,
  [DS_DATA_KEYS.SOP]: 12,
};

export const REPORT_BODY = [
  [DS_DATA_KEYS.VC],
  [DS_DATA_KEYS.LAAC, DS_DATA_KEYS.LDAC, DS_DATA_KEYS.LADAC, DS_DATA_KEYS.AADAC],
  [DS_DATA_KEYS.BOP, DS_DATA_KEYS.SOP],
];

export const DATE_FILTER = [{ key: ['from', 'to'], type: FilterTypes.r }];
