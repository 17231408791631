import { createBaseApi, configRequest, prepareQuery } from './baseAPI';

const baseApi = createBaseApi();

export const newsService = {
  getNews,
  getNewsById,
  createNews,
  updateNews,
  deleteNews,
};

function getNews(params = {}) {
  return baseApi.get(`/news?${prepareQuery(params)}`, configRequest);
}

function getNewsById(id) {
  return baseApi.get(`/news/${id}`, configRequest);
}

function createNews(body) {
  return baseApi.post('/news', body, configRequest);
}

function updateNews(body, id) {
  return baseApi.put(`/news/${id}`, body, configRequest);
}

function deleteNews(id) {
  return baseApi.delete(`/news/${id}`, configRequest);
}
