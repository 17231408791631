import { UNITS } from '@bs/techconnect-ui';

export const AFS_DATA_KEYS = {
  VC: 'vehicleCount',
  FC: 'fuelConsumptionAvg',
};

export const AFS_DATA_TITLES = {
  [AFS_DATA_KEYS.VC]: 'Автомобилей',
  [AFS_DATA_KEYS.FC]: 'на 100 км',
};

export const AFS_DATA_UNITS = {
  [AFS_DATA_KEYS.FC]: UNITS.volume,
};

export const mapFuelDataCharts = (report = {}) => {
  const { items = [] } = report;
  if (!items || items.length === 0) {
    return [];
  }
  const data =
    items.map(({ odometerEnd, fuelConsumptionAvg }) => {
      return { name: odometerEnd, fuelConsumptionAvg: fuelConsumptionAvg };
    }) || [];
  // задаем начало первых данных из пакета
  const { odometerStart, fuelConsumptionAvg } = items[0];
  data.unshift({ name: odometerStart, fuelConsumptionAvg: fuelConsumptionAvg });

  return data;
};
