import { ERRORS } from '@bs/techconnect-ui';

export const COLUMNS = [
  { key: 'title', title: 'Заголовок' },
  { key: 'shortDescription', title: ' Короткое описание' },
  { key: 'createdAt', title: 'Дата создания' },
  { key: 'pushNumber', title: 'Кол-во отправленных уведомлений' },
  { key: 'openedCount', title: 'Кол-во уникальных просмотров' },
  { key: 'edit', title: '' },
  { key: 'delete', title: '' },
];

export const NEWS_FORM = [
  { id: 'title', title: 'заголовок', options: { required: ERRORS.REQUIRED('заголовок') } },
  { id: 'shortDescription', title: 'короткое описание', options: { required: ERRORS.REQUIRED('короткое описание') } },
  { id: 'description', title: 'текст новости', options: { required: ERRORS.REQUIRED('текст новости') } },
];

export const PUSH_TYPE_LIST = [
  { id: 'immedently', title: 'немедленно' },
  { id: 'delay', title: 'отложенно' },
];
