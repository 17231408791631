import { useCallback, useEffect, useState } from 'react';
import { useAuth } from 'react-oidc-context';

import { userService } from 'services';
import { useUser } from 'helpers';
import Header from '../Header/Header';
import Navigate from '../Navigate/Navigate';
import Body from '../Body/Body';
import { Loader } from '@bs/techconnect-ui';

import styles from './MainLayout.module.css';

const MainLayout = () => {
  const { isAuthenticated } = useAuth();

  const { setUser } = useUser();
  const [loadingUser, setLoadingUser] = useState(false);

  const getUser = useCallback(async () => {
    if (isAuthenticated) {
      setLoadingUser(true);
      const { data, status } = (await userService.getSelf()) || {};
      if (status === 200) setUser(data);
      setLoadingUser(false);
    }
  }, [isAuthenticated, setUser]);

  useEffect(() => {
    getUser();
  }, [getUser]);

  if (loadingUser) return <Loader />;

  return (
    <div className={styles['app']}>
      <Header />
      <Navigate />
      <Body />
    </div>
  );
};

export default MainLayout;
