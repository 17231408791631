import { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { notificationsService } from 'services';
import { FILTERS } from 'components/Vehicles/helpers';
import { COLUMNS, EVENT_TYPES } from './helpers';
import { useSuccess } from 'helpers';

import { ListFilters, Table, parseDate, parseBrandModel, pageSize, Copy, shortString } from '@bs/techconnect-ui';

import styles from './Notifications.module.css';

const Notifications = () => {
  const { setSuccess } = useSuccess();
  const [searchParams] = useSearchParams();
  const page = searchParams.get('page') || 1;

  const [rows, setRows] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState({});

  const fetchNotifications = useCallback(() => {
    setLoading(true);
    notificationsService
      .getSystemAlerts({ page, pageSize, ...filters })
      .then(({ status, data }) => {
        if (status === 200 && Array.isArray(data?.items)) {
          setRows(
            data.items.map(item => ({
              ...item,
              brandModel: parseBrandModel(item?.vehicle),
              vin: item?.vehicle?.vin,
              plateNumber: item?.vehicle?.plateNumber,
              type: EVENT_TYPES[item?.type] || item?.type,
              date: parseDate(item?.eventDate),
            })),
          );
          if (!isNaN(data.totalPages)) setTotalPages(data.totalPages);
        }
      })
      .finally(() => setLoading(false));
  }, [filters, page]);

  useEffect(() => {
    fetchNotifications();
  }, [page, filters, fetchNotifications]);

  const handler = row => ({
    ...row,
    deviceId: (
      <Copy value={row.deviceId} callback={() => setSuccess('Данные скопированы')}>
        {shortString(row.deviceId)}
      </Copy>
    ),
    vin: row?.vehicle?.vin,
    plateNumber: row?.vehicle?.plateNumber,
    type: EVENT_TYPES[row?.type] || row?.type,
    date: parseDate(row?.eventDate),
  });

  return (
    <>
      <ListFilters
        title="Уведомления"
        fields={FILTERS}
        filters={filters}
        setFilters={setFilters}
        updateList={fetchNotifications}
      />

      <Table
        className={styles['table']}
        columns={COLUMNS}
        rows={rows}
        totalPages={totalPages}
        loading={loading}
        idKey="rid"
        handler={handler}
      />
    </>
  );
};

export default Notifications;
