import { useState } from 'react';
import { useForm } from 'react-hook-form';

import { vehicleService } from 'services';
import { ROLES, useUser } from 'helpers';
import { Input, Button, parseDate, ERRORS } from '@bs/techconnect-ui';
import CreateVehicle from './CreateVehicle/CreateVehicle';

import styles from './VehicleConnect.module.css';

const STATUSES = {
  NotDevice: 'С транспортным средством не связано устройство',
  NotProvisioned: 'NOK - Устройство не подключено к серверу',
  Provisioned: 'NOK - Устройство подключено к серверу, но не отправляет телеметрию',
  NotConfigured: 'NOK - Устройство подключено к серверу, отправляет телеметрию, но не сконфигурировано',
  Ready: 'OK - Устройство готово к работе',
};

const VehicleConnect = () => {
  const {
    reset,
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({ mode: 'onTouched' });

  const [deviceStatus, setDeviceStatus] = useState(undefined);
  const [lastActivity, setLastActivity] = useState(undefined);

  const { user } = useUser();
  const isAdminOrDealer = user?.roles.find(r => r === ROLES.admins || r === ROLES.dealer);

  const check = ({ vin }) => {
    vehicleService.checkVehicle(vin).then(({ status, data }) => {
      if (status === 200) {
        const { device, lastActivity: la } = data || {};
        if (!device) {
          setDeviceStatus(STATUSES.NotDevice);
        } else {
          setDeviceStatus(STATUSES[device.status]);
        }
        if (la) setLastActivity(parseDate(la));
      }
    });
  };

  return (
    <div className={styles['wrapper']}>
      {isAdminOrDealer && <CreateVehicle resetCheck={reset} handleSubmitCheck={handleSubmit(check)} />}

      <div className={styles['check']}>
        <form onSubmit={handleSubmit(check)}>
          <header>Проверка статуса телематического блока</header>

          <Input
            className={styles['field']}
            placeholder="VIN"
            register={register('vin', {
              required: ERRORS.REQUIRED('VIN'),
              validate: v => v.length === 17 || ERRORS.LENGTH(17),
            })}
            error={errors.vin}
          />
          <Button className={styles['submit']} disabled={!isValid} type="submit">
            Проверить
          </Button>

          <div className={styles['check-status']}>
            <span>Статус</span>
            {!!deviceStatus && <span>{deviceStatus}</span>}
          </div>
          <div className={styles['check-status']}>
            <span>Последняя активность</span>
            {!!lastActivity && <span>{lastActivity}</span>}
          </div>
        </form>
      </div>
    </div>
  );
};

export default VehicleConnect;
