export const COLUMNS = [
  { key: 'deviceId', title: 'DeviceId' },
  { key: 'brandModel', title: 'Модель' },
  { key: 'vin', title: 'VIN' },
  { key: 'plateNumber', title: 'Гос. номер' },
  { key: 'type', title: 'Тип уведомления' },
  { key: 'date', title: 'Время' },
];

export const EVENT_TYPES = {
  checkEngine: 'Check Engine',
  ParticulateFilter: 'ParticulateFilter',
};
